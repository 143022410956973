<template>
  <Card class="p-6 pt-0 shadow-md flex flex-col">
    <template #card-header>
      <div
        v-if="has(image.src) && has(image.alt)"
        class="rounded-full overflow-hidden w-40 h-40 mt-5 mx-auto border-6"
        :class="
          getCssClass(
            ['border-gray-200', 'bg-gray-200'],
            ['border-int-grey-04', 'bg-int-grey-04']
          )
        "
      >
        <LazyImg
          src="/images/lz.png"
          class="w-40 h-40 object-cover object-center"
          :data-src="imgSource(image.src)"
          :alt="image.alt"
        />
      </div>
      <header class="flex flex-col items-center">
        <h3 v-if="has(title)" class="mt-5 text-xl text-center">
          {{ title }}
        </h3>
        <h5
          v-if="has(subtitle)"
          class="mt-3 leading-6 text-base text-center"
          :class="getCssClass('text-gray-600', 'text-int-grey-02')"
        >
          {{ subtitle }}
        </h5>
      </header>
    </template>
    <div v-if="has(content)" class="mt-3 text-base text-center">
      {{ content }}
    </div>
  </Card>
</template>

<script>
import useHelpers from '~/composables/useHelpers'
import useTheme from '~/composables/theme/useTheme'

export default {
  name: 'TestimonialCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    image: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const { getImgSrc } = useHelpers()
    const { getCssClass } = useTheme()
    return { getImgSrc, getCssClass }
  },
  methods: {
    has(value) {
      return value && value.length
    },
    imgSource(imgSrc) {
      return this.getImgSrc(imgSrc)
    }
  }
}
</script>
